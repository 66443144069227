import React from 'react';
import { graphql } from 'gatsby';
import {
  Box, Button, Grommet, Heading, Image,
} from 'grommet';
import { Link as LocalLink } from 'react-scroll';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Contato from '../components/contato';

import vitorTema from '../components/theme';
import useSiteMetadata from '../components/use-site-metadata';


const PageTemplate = (props) => {
  const currentPage = props.data.wordpressWpAtendimento;
  const { title } = useSiteMetadata();

  return (
    <Layout isHome={false}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${title} - ${currentPage.title}`}</title>
      </Helmet>
      <Grommet theme={vitorTema}>
        <div className="o-container o-container--grid-12">
          <div className="u-center-10">
            <Box
              align="center"
              className="atendimento-icone"
              dangerouslySetInnerHTML={{ __html: currentPage.icone }}
            />
            <Heading
              margin="none"
              textAlign="center"
              className="u-mt-s1 u-mb-s1 u-main-color"
            >
              {currentPage.title}
            </Heading>
            <div
              dangerouslySetInnerHTML={{ __html: currentPage.acf.introducao }}
              className="u-bigger-text u-mb-s1"
            /><div></div>
            <Heading margin="none" level="3" className="u-main-color u-mb-s1">
              {currentPage.acf.subtitulo}
            </Heading>
            <div className="o-container--grid-2 s-wp-text">
              <div
                dangerouslySetInnerHTML={{ __html: currentPage.acf.coluna_1 }}
              />
              <div
                dangerouslySetInnerHTML={{ __html: currentPage.acf.coluna_2 }}
              />
            </div>
          </div>
        </div>
        <div className="o-container o-container--grid-12 u-mt-s2">
          <div className="u-center-10 o-container--grid-10">
            <Box className="c-call-to-action u-span-6">
              <h1>
                Está precisando se conhecer? Vai ser um prazer conversar com
                você
                </h1>

            </Box>

          </div>

        </div>
        <Contato />
      </Grommet>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpAtendimento(id: { eq: $id }) {
      title
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 4000) {
              src
            }
          }
        }
      }
      content
      icone
      date(formatString: "MMMM DD, YYYY")
      acf {
        introducao
        subtitulo
        coluna_1
        coluna_2
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
